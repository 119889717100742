exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-ai-ml-solutions-js": () => import("./../../../src/pages/ai-ml-solutions.js" /* webpackChunkName: "component---src-pages-ai-ml-solutions-js" */),
  "component---src-pages-app-development-services-js": () => import("./../../../src/pages/app-development-services.js" /* webpackChunkName: "component---src-pages-app-development-services-js" */),
  "component---src-pages-ar-vr-services-js": () => import("./../../../src/pages/ar-vr-services.js" /* webpackChunkName: "component---src-pages-ar-vr-services-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-digital-marketing-seo-services-js": () => import("./../../../src/pages/digital-marketing-seo-services.js" /* webpackChunkName: "component---src-pages-digital-marketing-seo-services-js" */),
  "component---src-pages-enterprise-cybersecurity-services-js": () => import("./../../../src/pages/enterprise-cybersecurity-services.js" /* webpackChunkName: "component---src-pages-enterprise-cybersecurity-services-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-iot-cloud-solutions-js": () => import("./../../../src/pages/iot-cloud-solutions.js" /* webpackChunkName: "component---src-pages-iot-cloud-solutions-js" */),
  "component---src-pages-professional-graphic-design-services-js": () => import("./../../../src/pages/professional-graphic-design-services.js" /* webpackChunkName: "component---src-pages-professional-graphic-design-services-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-web-3-js": () => import("./../../../src/pages/web3.js" /* webpackChunkName: "component---src-pages-web-3-js" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

